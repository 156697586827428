import React, { Component } from "react"
import Layout from "../../components/layout"
import Clutch from "../../components/clutch"

import Ansel_logo from "../../images/anselivy/anselivy.svg"
import Ansel_Top from "../../images/anselivy/top-images.svg"
import Traffic from "../../images/anselivy/traffic.svg"
import Revenue from "../../images/anselivy/revenue.svg"
import About_Ansel from "../../images/anselivy/about-ansel.jpg"
import Ansel_Challenge from "../../images/anselivy/ansel-challenge.jpg"
import Icon1 from "../../images/anselivy/house.svg"
import Icon2 from "../../images/anselivy/time.svg"
import Icon3 from "../../images/anselivy/users.svg"
import Ansel_Btm from "../../images/anselivy/ansel-btm1.png"

import Scroll_top_img from "../../images/scroll-arrow.png"
import Helmet from "react-helmet"
import "../../styles/includes/_google-shopping-ansel-and-ivy.less";
import { Link } from "gatsby";

import Calltoaction from "../../components/Call-to-action"

export default class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: ''
        }
    }

    componentDidMount() {
        this.setState({
            url: document.location.href
        });
    }
    render() {
        return (
            <Layout>
                <Helmet>
                    <title>Case Study on Google Shopping | Ansel & Ivy</title>
                    <meta name="description"
                        content="Read our Google Shopping Case Study on how CueForGood improved Ansel & Ivy’s Google Shopping Campaign to generate 12.35% of total revenue." />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <link rel="canonical" href={this.state.url} />

                    <meta property="og:site_name" content="CueForGood" />
                    <meta property="fb:app_id" content="289086684439915" />
                    <meta property="og:url" content={this.state.url} />
                    <meta property="og:title"
                        content="Case Study on Google Shopping | Ansel & Ivy" />
                    <meta property="og:description"
                        content="Read our Google Shopping Case Study on how CueForGood improved Ansel & Ivy’s Google Shopping Campaign to generate 12.35% of total revenue." />
                    <meta property="og:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:description"
                        content="Read our Google Shopping Case Study on how CueForGood improved Ansel & Ivy’s Google Shopping Campaign to generate 12.35% of total revenue." />
                    <meta name="twitter:title"
                        content="Case Study on Google Shopping | Ansel & Ivy" />
                    <meta name="twitter:site" content="@CueForGood" />
                    <meta name="twitter:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                </Helmet>
               <div className="cb_expertiseInner">
                  <div className="case_study_page perennialcycle organic-perennial ansel-ivy-googleshopping">

                     <div className="case-wrap  ansel-casewrap">
                           <div className="ww-sec-one ">
                              <div className="container">
                                 <h3>Case Study</h3>
                                 <h1><img style={{ maxWidth: 200 }} src={Ansel_logo} alt="Ansel and Ivy logo" /></h1>
                             <p className="ansel_p">How CueForGood helped Ansel & Ivy turn Google Shopping into third best traffic & revenue source - 12.35% of total revenue and 15.18% of total conversions.</p>

                                 <div className="images-top"><img src={Ansel_Top} alt="Ansel and Ivy" /></div>
                            
                                 <div className="revenue-perennial">
                                    <div className="revenue-inn">
                                       <div className="revenue-single circle1">
                                   <div className="revenue-single-outer">
                                         <div className="progress yellow1">
                                            
                                            <span className="progress-right">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right hiddenprogress">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value"> 12.35%</div>
                                         </div>
                                      </div>
                                      <p>Of Total <br/>Revenue</p>
                                       </div>
                                    
                                         <div className="revenue-single circle1">
                                   <div className="revenue-single-outer">
                                         <div className="progress yellow2">
                                            
                                            <span className="progress-right">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right hiddenprogress">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value"> 15.18%</div>
                                         </div>
                                      </div>
                                      <p>Of Total <br/>Conversions</p>
                                       </div>
                                           <div className="revenue-single circle1">
                                   <div className="revenue-single-outer">
                                         <div className="progress yellow3">
                                            
                                            <span className="progress-right">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right hiddenprogress">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value"> 13.04%</div>
                                         </div>
                                      </div>
                                      <p>Of Total <br/>Traffic</p>
                                       </div>
                                    </div>
                                 </div>
                             
                             </div>
                           </div>
                     </div>

                     <div className="cb_good_section call-action-component">
                        <Calltoaction sid="ansel1"/>
                     </div>

                     <div className="google-shop">
                         <div className="container">
                              <p>Google Shopping, for Ansel & Ivy, Is now the</p>
                              <ul>
                                 <li>
                                    <span><img src={Traffic} alt="3rd Best Traffic Generating Platform " /></span>
                                    <h4>3rd Best <br/>Traffic Generating <br/>Platform </h4>
                                 </li>
                                  <li>
                                    <span><img src={Revenue} alt="3rd Best Revenue Generating Platform  " /></span>
                                    <h4>3rd Best <br/>Revenue Generating  <br/>Platform </h4>
                                 </li>
                              </ul>
                            </div>
                     </div>
                      <div className="about-client-Ansel">
                         <div className="container">
                              <p className="note"><strong>Note:</strong> Measuring the performance from August 2020 till September 2021 as we started working on the project from 22 July 2020 onwards.</p>
                            <div className="about-inn">
                              <div className="about-left">
                                 <h2 className="heading_main">About the Client </h2>
                                 <p>Ansel & Ivy is an eCommerce shop that delivers a healthy range of
indoor/outdoor houseplants along with ceramic pots, watering cans, and other accessories at affordable prices. Their Google Shopping Platform boasts of around 450 products (including all variants). </p>
                              </div>
                              <div className="about-right">
                                 <img src={About_Ansel} alt="About the Client  " />
                              </div>
                            </div>
                            </div>
                     </div>



                    <div className="Anselcasestudy-background">
                       <div className="container">
                                   <h2 className="heading_main">Background</h2>
                                <p>After developing Ansel & Ivy’s website and migrating the same from Magento to Shopify, our team at CueForGood looked for ways to generate more 
                                   sales for the company. While analysing the brand, we noticed its absence from the Google Shopping Platform. Considering the aesthetics, product
                                    range, competitors, and market growth, it was clearly a missed opportunity.</p>
                                    <p>We, therefore, advised the client to try Google Shopping for amplified traffic, sales, and ROI for at least 3 months. Starting 22 July 2020,
                                        we experienced striking returns on the ad spent within 3 months. Consequently, the client decided to continue working on the channel which has
                                         now made its way to becoming one of the top traffic & revenue-generating sources for the website.</p>
                       </div>
                    </div>

                    <div className="Challenges  anselivy-challenges">
                        <div className="container">
                           <h2 className="heading_main">Challenges</h2>
                           <div className="out-Challenges">
                           <div className="inn-Challenges">
                               <h2 className="heading_count">1</h2>
                               <h3>Competitive niche</h3>
                              <p>The e-commerce market’s growth momentum for gardening products in the USA is forecasted to accelerate at a compound annual growth rate (CAGR) of 9.64%. This prediction indicates how saturated this niche is.</p>
                           </div>
                           <div className="inn-Challenges">
                               <h2 className="heading_count">2</h2>
                               <h3>No previous data to help</h3>
                              <p>Since we were starting from scratch, we had no previous data looking at which we could optimize the Google Shopping ads.</p>
                           </div>
                          
                       
                           <div className="inn-Challenges">
                               <h2 className="heading_count">3</h2>
                               <h3>Insufficient contact information</h3>
                              <p>Contact information on the website is important to set up the Google Merchant Center (GMC) account. Because the website didn’t have enough contact information displayed, we had to ask the client to make the requisite changes.</p>
                           </div>
                           <div className="inn-Challenges">
                                <img src={Ansel_Challenge} alt="Ansel & Ivy Service" />
                           </div>
                          
                           </div>
                           
                        </div>
                     </div>

                     <div className="Anselcasestudy-whatwedid">
                       <div className="container">
                                   <h2 className="heading_main">What We Did</h2>
                                <p>We ensured that the products offered by Ansel & Ivy appeared</p>
                                 <ul>
                                    <li>
                                       <span className="icon"> <img src={Icon1} alt="At the right place" /></span>
                                       <p>At the right place</p>
                                    </li>
                                    <li>
                                       <span className="icon"> <img src={Icon2} alt="At the right time" /></span>
                                       <p>At the right time</p>
                                    </li>
                                    <li>
                                       <span className="icon"> <img src={Icon3} alt="For the right customers" /></span>
                                       <p>For the right customers</p>
                                    </li>
                                 </ul>

                       </div>
                    </div>

                  <div className="Challenges  anselivy-challenges how-ansel">
                        <div className="container">
                           <h2 className="heading_main">Here’s How</h2>
                           <div className="out-Challenges">
                           <div className="inn-Challenges">
                               <h2 className="heading_count">1</h2>
                               <h3>Market analysis</h3>
                              <p>After putting heads together, we found that the online gardening industry is the most competitive in the USA and that it will continue to boom. Research showed us a growing number of gardening brands that were marketing on CSEs. Our hunt for a platform that could give Ansel & Ivy both sales and an increased ROI ended with Google Shopping Product Ads as we foresaw this unexplored marketing platform bringing promising results.</p>
                           </div>
                           <div className="inn-Challenges">
                               <h2 className="heading_count">2</h2>
                               <h3>GMC account set up</h3>
                              <p>Google Merchant Center (GMC) account helps get the product info into Google, making it available to shoppers across Google. Since the website didn’t have their contact information displayed, we requested them to mention it upfront so GMC could be created with ease. While, earlier, Google’s Insufficient Contact Information Policy stated having two forms of contact information as mandatory, it has now been reduced to one (starting Aug 2021).</p>
                           </div>
                          
                       
                           <div className="inn-Challenges">
                               <h2 className="heading_count">3</h2>
                               <h3>Product feed optimization</h3>
                              <p>Since product feed is at the centre of the Google Shopping campaigns, its optimization is essential so the listing ads can be displayed for relevant shopper search queries. For this, we took the following steps:</p>
                              <ul>
                                 <li>Reviewed the product catalogue.</li>
                                 <li>Configured fields.</li>
                                 <li>Created custom fields.</li>
                                 <li>Made sure images were high-quality.</li>
                                 <li>Used long-tail & short-tail keywords in titles & descriptions.</li>
                                 <li>Ensured proper categorization for each product in the Data Feed.</li>
                              </ul>
                           </div>
                             <div className="inn-Challenges">
                               <h2 className="heading_count">4</h2>
                               <h3>Attribute specifications</h3>
                              <p>We ensured that we had correct and sufficient data in all possible attributes to help Google display accurate product listings for relevant search queries.</p>
                           </div>
                             <div className="inn-Challenges">
                               <h2 className="heading_count">5</h2>
                               <h3>404 error fixation</h3>
                              <p>We checked each product URL to ensure no 404 errors or out-of-stock products. This was important to offer shoppers the best ad-to-landing page experience.</p>
                           </div>
                           <div className="inn-Challenges">
                               <h2 className="heading_count">6</h2>
                               <h3>Product URL customization</h3>
                              <p>In order to track visits through Product Listing ads (PLA) in Google Analytics, we customized the product URLs and added tracking parameters to them.</p>
                           </div>
                          
                           </div>
                           
                        </div>
                     </div>

                     <div className="cb_good_section call-action-component">
                        <div className="service_weoffer cb_testimonails">
                        <Calltoaction sid="ansel2"/>
                        </div>
                     </div>
             
                  <div className="case-wrap  ansel-casewrap result-ansel">
                           <div className="ww-sec-one ">
                              <div className="container">
                                      <h2 className="heading_main">Results</h2>
                                      <h6>Starting 22 July 2020, Google Shopping has benefited Ansel & Ivy By</h6>
                                 <div className="revenue-perennial">
                                    <div className="revenue-inn">
                                       <div className="revenue-single circle1">
                                   <div className="revenue-single-outer">
                                         <div className="progress yellow1">
                                            
                                            <span className="progress-right">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right hiddenprogress">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value"> 12.35%</div>
                                         </div>
                                      </div>
                                      <p>Of Total <br/>Revenue</p>
                                       </div>
                                    
                                         <div className="revenue-single circle1">
                                   <div className="revenue-single-outer">
                                         <div className="progress yellow2">
                                            
                                            <span className="progress-right">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right hiddenprogress">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value"> 15.18%</div>
                                         </div>
                                      </div>
                                      <p>Of Total <br/>Conversions</p>
                                       </div>
                                           <div className="revenue-single circle1">
                                   <div className="revenue-single-outer">
                                         <div className="progress yellow3">
                                            
                                            <span className="progress-right">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right hiddenprogress">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value"> 13.04%</div>
                                         </div>
                                      </div>
                                      <p>Of Total <br/>Traffic</p>
                                       </div>
                                    </div>
                                 </div>
                             
                             </div>
                           </div>
                     </div>
                  <div className="google-shop google-shop-btm">
                         <div className="container">
                              <ul>
                                 <li>
                                    <span><img src={Traffic} alt="3rd Best Traffic Generating Platform " /></span>
                                    <h4>3rd Best <br/>Traffic Generating <br/>Platform </h4>
                                 </li>
                                  <li>
                                    <span><img src={Revenue} alt="3rd Best Revenue Generating Platform  " /></span>
                                    <h4>3rd Best <br/>Revenue Generating  <br/>Platform </h4>
                                 </li>
                              </ul>
                            </div>
                     </div>

                       <div className="footer-atg footer-ansel">
                        <div className="container">
                        <p>Want to give your online sales a boost? We’re right here to help. Get in
                        touch with us at <a tabIndex={0} href="mailto:letstalk@cueforgood.com" className="focus-visible">letstalk@cueforgood.com</a> to know how we can add value to
                        your business.</p>
                               <img src={Ansel_Btm} alt="Ansel and Ivy" />
                        </div>
                     </div>  

                 
               

                    <div id="scroll-to" className="case-scroll">
                       <a href="#" title="Scroll To Top" className="focus-visible"><img src={Scroll_top_img} alt="Scroll To Top" /></a>
                    </div>
                  </div>
               </div>

                <Clutch />

            </Layout>
        )
    }
}